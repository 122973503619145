import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout title="404">
    <section
      className="section secondary-section"
      style={{ marginTop: "-0.3rem" }}
    >
      <div className="container">
        <div className="has-text-weight-bold title-box-primary">
          <h2>Sivua ei löytynyt.</h2>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="content">Etsimääsi sivua ei löytynyt.</div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
